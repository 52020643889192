import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from 'src/app/services/content.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfigurationQuery } from 'src/app/state/configuration/configuration.query';
import { DataQuery } from 'src/app/state/data/data.query';
import { DataService } from 'src/app/state/data/data.service';
import { UserData } from 'src/app/state/digitalAssistent/user-data.model';
import { UserService } from 'src/app/state/user/user.service';
import { environment } from 'src/environments/environment';

// Declare gTM dataLayer array.
declare global {
  interface Window { dataLayer: any[]; }
}
@Component({
  selector: 'app-login-light-form',
  templateUrl: './login-light-form.component.html',
  styleUrls: ['./login-light-form.component.scss', '../login-light-sidebar.component.scss']
})

export class LoginLightFormComponent implements OnInit, AfterViewInit {

  formInvalid = true;
  language: string = 'de-DE';

  @Output() saveLoginLightProgress = new EventEmitter<any>();
  @ViewChild('firstName') firstName!: any;
  @ViewChild('lastName') lastName!: any;
  @ViewChild('email') email!: any;

  userDataForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    mail: new FormControl('', Validators.required),
    agb: new FormControl(false, Validators.required),
    ds: new FormControl(false, Validators.required)
  });

  toggleCheckboxLabelClass = 'login-light-checkbox';

  genders = [
    {
      label: "COMMON.FRAU",
      value: "FRAU",
      width: '120px'
    },
    {
      label: "COMMON.HERR",
      value: "HERR",
    },
    {
      label: "COMMON.DIVERS",
      value: "DIVERS",
    }
  ];
  groupLabel = 'Anrede wählen *';

  dataLayer = window.dataLayer || [];
  type = environment.webappType === 'a' ? {'event': 'DAA_SEK_01'} : environment.webappType === 'b' ? {'event': 'DAB_SEK_01'} : {'event': 'DAC_SEK_01'};

  constructor(
    public contentService: ContentService,
    private screen: LayoutService,
    private userService: UserService,
    private dataQuery: DataQuery,
    private daService: DataService,
    private configurationQuery: ConfigurationQuery,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (!!this.contentService.getValue('haushaltPerson')) {
      this.userDataForm.controls['gender'].setValue(this.contentService.getValue('haushaltPerson') === 'MANN' ? 'HERR' : this.contentService.getValue('haushaltPerson'));
    }
  }

  ngAfterViewInit(): void {
    this.setLabels();
  }

  setLabels() {
    this.configurationQuery.configuration.subscribe((res: any) => {
      if (res) {
        let datenschutz = `${this.translate.instant('LOGIN_LIGHT.FORM.READ1')} <a style="color: inherit;" href="${res.kontaktlinkdse}" target="_blank">${this.translate.instant('LOGIN_LIGHT.FORM.READ_PRIVACY_POLICY')}</a> ${this.translate.instant('LOGIN_LIGHT.FORM.READ2')}`;
        let agb = `${this.translate.instant('LOGIN_LIGHT.FORM.READ1')} <a style="color: inherit;" href="${res.kontaktlinkagb}" target="_blank">${this.translate.instant('LOGIN_LIGHT.FORM.READ_GENERAL_TERMS')}</a> ${this.translate.instant('LOGIN_LIGHT.FORM.READ2')}`;
        this.contentService.convertStringToHtmlForClass(datenschutz, this.toggleCheckboxLabelClass, 0);
        this.contentService.convertStringToHtmlForClass(agb, this.toggleCheckboxLabelClass, 1);
      }
    });
  }

  onSaveLoginLightProgress() {
    let userdata: UserData = {
      vorname: this.userDataForm.value.firstName,
      nachname: this.userDataForm.value.lastName,
      anrede: this.userDataForm.value.gender,
      mail: this.userDataForm.value.mail,
      agb: this.userDataForm.value.agb,
      datenschutz: this.userDataForm.value.ds
    };

    setTimeout(() => { this.userService.refill(userdata) }, 100);

    window.dataLayer.push(this.type);
    this.saveLoginLightProgress.emit(userdata);
  }

  onOptionChange(data: any) {
    this.userDataForm.controls[data.formControlName].setValue(data.value);
  }

  setGenderSwitch(e: any) {
    this.userDataForm.controls['gender'].setValue(e);
    this.daService.update(1, { haushaltPerson: e === 'HERR' ? 'MANN' : e });
    this.daService.update(1, { zahlendePerson: e === 'HERR' ? 'MANN' : e });
    this.formIsValid();
  }

  // convertStringToHtmlForClass(content: string, elementClass: string, elementIndex: number) {
  //   this.contentService.convertStringToHtmlForClass(content, elementClass, elementIndex);
  // }

  getGender() {
    return this.userDataForm.value.gender;
  }

  isMobile() {
    return this.screen.isMobile();
  }

  isMobileOrSmallTablet() {
    return this.screen.isMobileOrSmallTablet();
  }

  validateFormField(e: any, element: any) {
    let value = e.srcElement.value;
    element.touched = !!value ? true : false;
    element.placeholder = '';
    let isValid = this.contentService.validatePattern(value, element.validationType);
    if (!isValid) {
      element.error = true
    } else {
      element.error = false
      this.updateStore(value, element.name);
    }
    this.formIsValid();
  }

  updateStore(value: string, type: string) {
    switch (type) {
      case 'firstName': {
        this.daService.update(1, { haushaltPersonFirstName: value });
        this.daService.update(1, { zahlendePersonFirstName: value });
      }
        break;
      case 'lastName': {
        this.daService.update(1, { haushaltPersonLastName: value });
        this.daService.update(1, { zahlendePersonLastName: value });
      }
        break;
      case 'mail': {
        this.daService.update(1, { haushaltPersonEmail: value });
        this.daService.update(1, { zahlendePersonEmail: value });
      }
        break;
    }
  }

  formIsValid() {
    this.formInvalid = true;
    setTimeout(() => {
      if (this.userDataForm) {
        let controls = this.userDataForm.controls;
        let values = this.userDataForm.value;
        for (let key in controls) {
          let control = controls[key];
          if (control.errors) {
            return;
          }
        }
        for (let key in values) {
          let value = values[key];
          if (typeof value === 'string' && value.length === 0) {
            return;
          }
          if (typeof value === 'boolean' && value === false) {
            return;
          }
        }
      }
      if (this.firstName.error || this.lastName.error || this.email.error) {
        return;
      }
      this.formInvalid = false;
    }, 100)
  }
}
